import { useMemo, useRef } from 'react';
export const useDelay = () => {
  const ref = useRef(undefined);
  return useMemo(() => {
    const start = (cb, delay) => {
      ref.current = setTimeout(() => {
        cb();
      }, delay);
    };
    const cancel = () => {
      if (ref.current) {
        clearTimeout(ref.current);
      }
    };
    return {
      start,
      cancel
    };
  }, []);
};