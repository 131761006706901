import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import { useDebouncedCallback } from 'use-debounce';
export const usePositionOnScreen = ref => {
  const {
    width
  } = useWindowSize();
  const [value, setValue] = useState({
    left: 0,
    top: 0
  });
  const debouncedValue = useDebouncedCallback(nextValue => {
    setValue(currentValue => {
      if (currentValue.left === nextValue.left && currentValue.top === nextValue.top) {
        return currentValue;
      }
      return nextValue;
    });
  }, 300);
  useEffect(() => {
    if (ref.current) {
      const top = ref.current.getBoundingClientRect().top + document.documentElement.scrollTop;
      const left = ref.current.getBoundingClientRect().left + document.documentElement.scrollLeft;
      debouncedValue({
        top,
        left
      });
    }
  }, [debouncedValue, ref, width]);
  return value;
};