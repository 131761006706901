import { styled, css } from 'styled-components';
import { pulsate } from '../../../../play-core/styles/pulsate';
import { Viewports } from '../../../../play-core/styles/Viewports';
const aspectRatioHeight = 100 / 16 * 9;
const aspectRatioHeightPercentage = `${aspectRatioHeight}%`;
const aspectRatioHeightViewWidth = `${aspectRatioHeight}vw`;

// This is a constant from Letterbox (can be found in subdivisions-container.js).
// Letterbox will *never* show segments if the container height is less than 280px.
const letterboxShowSegmentsMinHeight = 280;
export const ScreenHeightLayout = styled.div.withConfig({
  displayName: "VideoPlayerLayoutstyles__ScreenHeightLayout",
  componentId: "sc-19uhorf-0"
})(["position:relative;min-height:", "px;", "{", ";}padding-top:", ";", ";"], letterboxShowSegmentsMinHeight, Viewports.for('small'), ({
  segmentsVisible
}) => !segmentsVisible && css(["min-height:", ";"], aspectRatioHeightViewWidth), ({
  isEmbedded,
  viewHeight
}) => isEmbedded && `${viewHeight}svh`, props => !props.isEmbedded && getContainerHeight(props));
export const PlayerContainer = styled.div.withConfig({
  displayName: "VideoPlayerLayoutstyles__PlayerContainer",
  componentId: "sc-19uhorf-1"
})(["position:absolute;top:0;bottom:0;left:0;right:0;width:100%;height:100%;", ""], props => props.isLoading && css(["animation:", " 1s ease-in-out infinite alternate;"], pulsate));
const getContainerHeight = ({
  viewHeight,
  screenHeightHeader,
  segmentsVisible,
  segmentViewHeight
}) => {
  const segmentHeight = segmentsVisible ? segmentViewHeight : 0;
  return css(["height:unset;max-height:unset;padding-top:min(calc(", " + ", "px),calc(", "svh - ", "px));", ";"], aspectRatioHeightPercentage, segmentHeight, viewHeight, screenHeightHeader.default, screenHeightHeader.mediaQuery.map(([viewport, top]) => css(["", "{padding-top:min(calc(", " + ", "px),calc(", "svh - ", "px));"], Viewports.for(viewport), aspectRatioHeightPercentage, segmentHeight, viewHeight, top)));
};