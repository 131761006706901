import { styled, css } from 'styled-components';
import { Viewports } from '../../../../play-core/styles/Viewports';
import { zIndex } from '../../../../play-core/styles/z-index';
import { IconWrapper } from '../IconWrapper/IconWrapper';
import { DensityAwareImage } from '../Image/DensityAwareImage';
import { defaultSegmentHeight } from '../Letterbox/Letterbox.constants';
export const ImagePlaceholder = styled(DensityAwareImage).withConfig({
  displayName: "VideoPlayerstyles__ImagePlaceholder",
  componentId: "sc-gcgqkc-0"
})(["object-fit:contain;box-sizing:content-box;"]);
export const Placeholder = styled.div.withConfig({
  displayName: "VideoPlayerstyles__Placeholder",
  componentId: "sc-gcgqkc-1"
})(["opacity:", ";transition:opacity 0.1s ease-in;position:absolute;height:", ";width:101%;top:-0.5%;left:-0.5%;pointer-events:none;> img{object-position:", ";}"], ({
  isLoaded
}) => isLoaded ? 0 : 1, ({
  hasSegments
}) => hasSegments ? `calc(101% - ${defaultSegmentHeight}px)` : '101%', ({
  hasSegments
}) => hasSegments ? 'top' : 'center');
export const Shadow = styled.div.withConfig({
  displayName: "VideoPlayerstyles__Shadow",
  componentId: "sc-gcgqkc-2"
})(["background-color:rgba(0,0,0,0.4);position:absolute;height:100%;width:100%;top:0;"]);
const preloaderFontSizes = {
  default: '3.4em',
  mediaQuery: [['medium', '3.7em'], ['large', '4.5em'], ['xLargeUp', '5.5em']]
};
export const PreloaderPlayButton = styled(IconWrapper).withConfig({
  shouldForwardProp: () => true
}).withConfig({
  displayName: "VideoPlayerstyles__PreloaderPlayButton",
  componentId: "sc-gcgqkc-3"
})(["position:absolute;width:100%;height:100%;z-index:", ";font-size:", ";", ";"], zIndex.abovePlayer, preloaderFontSizes.default, preloaderFontSizes.mediaQuery.map(([viewport, fontSize]) => css(["", "{font-size:", ";}"], Viewports.for(viewport), fontSize)));