import { useMemo } from 'react';
import { calculateProgress } from './calculate-progress';
import { findMedia } from './media-composition-helpers';
import { urnToId } from '../../../../play-core/utils/urn-helper';
import { trackVideoProgress } from '../../api/track-video-progress';
import { useVideoConfig } from '../../config/VideoAppContext';
import { useUserIdState } from '../../providers/DataProvider/DataContext';
import { maxWatchedPercentage } from '../../providers/VideoHistoryProvider/reducer';
import { useVideoHistoryDispatch } from '../../providers/VideoHistoryProvider/VideoHistoryContext';
export const useVideoProgressTracker = urn => {
  const {
    userId
  } = useUserIdState();
  const {
    businessUnit,
    ilHost
  } = useVideoConfig();
  const videoHistoryDispatch = useVideoHistoryDispatch();
  return useMemo(() => createVideoProgressTracker(ilHost, businessUnit, videoHistoryDispatch, urn, userId), [ilHost, businessUnit, videoHistoryDispatch, urn, userId]);
};
export const createVideoProgressTracker = (ilHost, businessUnit, dispatch, urn, userId) => {
  let lastTrackedProgress;
  return (progressInSeconds, mediaComposition) => {
    const progress = calculateProgress(progressInSeconds, mediaComposition, urn);
    if (urn && progress?.progressInPercentage) {
      dispatch({
        type: 'update-progress',
        mediaUrn: urn,
        progress,
        showUrn: mediaComposition.show?.urn
      });
      const trackingStep = getTrackingStep(progress.progressInPercentage);
      const eventData = getEventData(mediaComposition, urn);
      if (userId && trackingStep && eventData && (!lastTrackedProgress || lastTrackedProgress !== trackingStep)) {
        trackVideoProgress(ilHost, businessUnit, urnToId(urn), {
          eventData,
          userId,
          progress: trackingStep
        });
        lastTrackedProgress = trackingStep;
      }
    }
  };
};
const getTrackingStep = progressInPercentage => {
  const trackingSteps = [maxWatchedPercentage, 80, 60, 40, 20, 10, 1];
  return trackingSteps.find(step => step <= progressInPercentage);
};
const getEventData = (mediaComposition, urn) => {
  const media = findMedia(mediaComposition, urn);
  return media?.eventData;
};