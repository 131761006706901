import { useMemo } from 'react';
import { urnToId } from '../../../../play-core/utils/urn-helper';
import { maxWatchedPercentage } from '../../providers/VideoHistoryProvider/reducer';
import { useVideoHistory } from '../../providers/VideoHistoryProvider/VideoHistoryContext';
export const useStoredVideoPosition = (urn, seekPosition, trackProgress) => {
  const {
    videoHistoryById
  } = useVideoHistory();
  return useMemo(() => seekPosition || getStoredPosition(trackProgress, videoHistoryById, urn),
  // We intentionally don't update when videoHistoryById changes, because we're only interested in the initial video position
  // and don't want updates of the seek position while the video is playing.
  // We can't directly access the local storage because in the scenario of rts with user-login the localstorage is not set
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [seekPosition, trackProgress, urn]);
};
const getStoredPosition = (trackProgress, history, urn) => {
  if (!trackProgress || !urn) {
    return 0;
  }
  const id = urnToId(urn);
  const videoProgress = history[id];
  if (videoProgress?.progressInPercentage) {
    return videoProgress.progressInPercentage >= maxWatchedPercentage ? 0 : videoProgress.progressInSeconds;
  }
  return videoProgress?.progressInSeconds ?? 0;
};