import { useCallback } from 'react';
import { useVideoProgressTracker } from './use-video-progress-tracker';
export const useHandleProgressChanges = (trackProgress, playbackUrn, onProgressChanged) => {
  const trackVideoProgress = useVideoProgressTracker(playbackUrn);
  const handleProgressChanged = useCallback((progressInSeconds, mediaComposition) => {
    if (trackProgress) {
      trackVideoProgress(progressInSeconds, mediaComposition);
    }
    onProgressChanged && onProgressChanged(progressInSeconds, mediaComposition);
  }, [trackProgress, onProgressChanged, trackVideoProgress]);
  return handleProgressChanged;
};